import React, { useEffect, useState } from "react";
import Secheader from "./component/Secondaryheader";
import Teams from "../data/Teamsdata";
import { useQuery } from "@apollo/client";
import { AboutPage, GetTeams } from "../services/graphql/query";
import Loader from "../components/Loader/Loader";


const Tcards = (props) => {
	return (
		<figure className="figure col-md-3 text-center block-team-item">
			<div className="block-team-item__img">
				<img src={props.timage} className="figure-img img-fluid rounded col-lg-6 col-7 col-md-10" alt="team3" />
			</div>
			<div className="block-team-item__des">
			  <h3 className="figure-caption text-dark block-team-item__title">{props.ttitle}</h3>
			  <p className="block-team-item__text" dangerouslySetInnerHTML={{ __html: props.tcontent }}></p>
			</div>
		</figure>
	);
};

const About = () => {

	const { loading, error, data } = useQuery(AboutPage);
	const { loading: teamLoading, error: teamError, data: teamData } = useQuery(GetTeams);
	const [isLoading, setIsLoading] = useState(true);

	let MainTitle = data?.page?.pageTitleField?.title;
	let MainSubTitle = data?.page?.pageTitleField?.subtitle;
	let MainTitleImage = data?.page?.pageTitleField?.titleImage?.mediaItemUrl;
	
	let content = data?.page?.content;
	let ourStoryTitle = data?.page?.aboutUs?.ourStoryTitle;
	let ourStoryContent = data?.page?.aboutUs?.ourStoryContent;

	let ourTeamTitle = data?.page?.aboutUs?.ourTeamTitle
	let ourTeamContent = data?.page?.aboutUs?.ourTeamContent
	
	useEffect(() => {
		setTimeout(() => {
		  setIsLoading(false);
		}, 2000);
	}, []);
	
	useEffect(() => {
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	}, []);

	return (
		<>
			{isLoading ? (
              <Loader />
            ) : (
				<>
					<div className="sec_hdr" style={{ backgroundImage: `url(${MainTitleImage})` }}>
						<Secheader 
							sectitle = {MainTitle}
							secdesc= {MainSubTitle} 
						/>
					</div>
					<section>
						<div className="container mx-auto py-5 col-lg-10 col-xl-9">
						<div
							dangerouslySetInnerHTML={{__html: content}}
						/>
						</div>
					</section>

					<section className="bg-light">
						<div className="container row py-5 m-auto col-lg-10 col-xl-9">
							<p className="display-6 text-center mb-4">{ourStoryTitle}</p>
							<div
								dangerouslySetInnerHTML={{__html: ourStoryContent}}
							/>
						</div>
					</section>

					<section>
						<div className="container py-5 m-auto">
							<div className="mb-4">
								<p className="display-6 text-center mb-1">{ourTeamTitle}</p>
								<p className="d-lg-block d-none col-8 m-auto text-center text-muted" dangerouslySetInnerHTML={{ __html: ourTeamContent }}></p>
							</div>
							<div className="row col-lg-11 d-flex align-items-center justify-content-evenly m-auto">
									{
										teamData?.allTeams?.nodes.map((val, ind) => {
											if(val.status === "publish") {
												return (
													<Tcards key={ind}
														timage={val.featuredImage.node.mediaItemUrl}
														ttitle={val.title}
														tcontent={val.content}
													/>
												);
											}
											return null; // You can return null for components you don't want to render
										})
									}
							</div>
						</div>
					</section>
				</>
			)}
		</>
	);
};

export default About;