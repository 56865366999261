import React from "react";



const Secheader = (props) => {
	return (
		<>
			{/* <div className="sec_hdr" style={{ backgroundImage: 'url("https://media.geeksforgeeks.org/wp-content/uploads/20201221222410/download3.png")'}}> */}
				<div className="secondary_header sec_bg d-flex justify-content-center align-items-center flex-column">
					<div className="container">
						<div className="text-center">
							<h2 className="display-6 hdrcolor">{props.sectitle}</h2>	
							<h5 className="text-white lead px-4 text-center">{props.secdesc}</h5>
						</div>
					</div>
				</div>
			{/* </div> */}
		</>
	);
};

export default Secheader;