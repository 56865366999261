import React, { Suspense, lazy } from "react";
import Navbar from "../../pages/component/Navbar";
import Footer from "../../pages/component/Footer";
// const Navbar = lazy(() => import("../../pages/component/Navbar"));
// const Footer = lazy(() => import("../../pages/component/Footer"));

const Layout = ({ children }) => {
  return (
    <div className="layout">
        <Navbar />
        {children}
        <Footer />
    </div>
  );
};

export default Layout;
