import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import Secheader from "./component/Secondaryheader";
import { GetServices, ServicesPage } from "../services/graphql/query";
import Loader from "../components/Loader/Loader";

const Service = () => {
    const { loading, error, data } = useQuery(ServicesPage);
    const { loading: serviceLoading, error: serviceError, data: serviceData } = useQuery(GetServices);
    const [isLoading, setIsLoading] = useState(true);
    const MainTitle = data?.page?.pageTitleField?.title;
    const MainSubTitle = data?.page?.pageTitleField?.subtitle;
    const MainTitleImage = data?.page?.pageTitleField?.titleImage?.mediaItemUrl;
    const ServiceTitle = data?.page?.services?.serviceTitle;
    const ServiceShortDescription = data?.page?.services?.serviceShortDescription;
    const ServiceBottomTitle = data?.page?.services?.serviceBottomTitle;
    const ServiceBottomDescription = data?.page?.services?.serviceBottomDescription;
    useEffect(() => {
		setTimeout(() => {
		    setIsLoading(false);
		}, 2000);
	}, []);

    useEffect(() => {
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	}, []);

    return (
        <>  
            {isLoading ? (
                <Loader />
            ) : (
			    <>
                    <div className="sec_hdr" style={{ backgroundImage: `url(${MainTitleImage})` }}>
                        <Secheader sectitle={MainTitle} secdesc={MainSubTitle} />
                    </div>
                    <section>
                        <div className="container mx-auto py-5">
                            <div className="mb-5 text-center">
                                <p className="display-6 mb-1">{ServiceTitle}</p>
                                <p className="text-muted">{ServiceShortDescription}</p>
                            </div>
                            <div className="row d-flex items-align-center justify-content-evenly block-service-list">
                                {serviceData?.allServices?.nodes.map((val, ind) => {
                                    if(val.status === "publish") {
                                        return (
                                            <div className="col-md-4 col-lg-4 mb-3 text-center">
                                                <div className="box_border col-lg-9 p-3 pt-4 m-auto rounded block-service-list__box">
                                                    <div className="block-service-list__img">
                                                    <img src={val.singleService.icon.mediaItemUrl} className="img-fluid mb-2" alt="cards" width="20%" />
                                                    </div>
                                                    <div className="block-service-list__des">
                                                        <Link to={`/service/${val.slug}`}><h5 className="my-2">{val.title}</h5></Link>
                                                        <p className="text-muted text-center" dangerouslySetInnerHTML={{ __html: val.excerpt }}></p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                    return null; // You can return null for components you don't want to render
                                })}
                            </div>
                        </div>
                    </section>
                    <section className="bg-light">
                        <div className="container py-5 text-center">
                            <p className="display-6">{ServiceBottomTitle}</p>
                            <p className="text-muted">
                                <span dangerouslySetInnerHTML={{ __html: ServiceBottomDescription }} />
                            </p>
                        </div>
                    </section>
                </>
            )}
        </>
    );
};

export default Service;
