import React, { useEffect, useState } from "react";
import Secheader from "./component/Secondaryheader"
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { ALL_OPTIONS } from "../services/graphql/query";
import { ContactPage } from "../services/graphql/query";
import { useQuery } from "@apollo/client";
import ContactForm from "./contactForm";
import Loader from "../components/Loader/Loader";
import axios from 'axios';
	
const Contact = () => {

	const [formData, setFormData] = useState({
		sname: '',
		email: '',
		message: '',
	  });
	
	  const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	  };
	
	  const handleSubmit = async (e) => {
		e.preventDefault();
	
		// Send the contact form data to the REST API
		const response = await axios.post(
		  'https://headless.qewebby.com/api/wp-json/contact-form-7/v1/contact-forms/198/feedback',
		  formData, {
			headers: {
			  'Content-Type': 'multipart/form-data', // or 'multipart/form-data' if required
			},
		  }
		);

		console.log( response.data.message );
	
		// Handle the response from the REST API
		if (response.status === 200) {
		  // Success!
		  	const successMessage = 'Your message has been sent successfully.';
			document.querySelector('.contact-form').innerHTML += `<p class="success-message">${successMessage}</p>`;

			// Make the form fields black
			document.querySelectorAll('.contact-form input, .contact-form textarea').forEach(field => field.value = '');
			//document.querySelectorAll('.contact-form input, .contact-form textarea').forEach(field => field.style.color = 'black');
		} else {
		  // Error!
		}
	  };
  

	const { loading, error, data: options } = useQuery(ALL_OPTIONS);
	const { loading: contactloading, error: contacterr, data: contactdata } = useQuery(ContactPage);
	const [isLoading, setIsLoading] = useState(false);
	let supportPhone = options?.page?.options?.supportPhone;
	let supportEmail = options?.page?.options?.supportEmail;
	let supportAddress = options?.page?.options?.supportAddress;
	let salesPhone = options?.page?.options?.salesPhone;
	let salesEmail = options?.page?.options?.salesEmail;
	let salesAddress = options?.page?.options?.salesAddress;
	const supportmailtoLink = `mailto:${supportEmail}`;
	const salesmailtoLink = `mailto:${salesEmail}`;
	const supporttelLink = `tel:${supportPhone}`;
	const salestelLink = `tel:${salesPhone}`;

	const MainTitle = contactdata?.page?.pageTitleField?.title;
	const MainSubTitle = contactdata?.page?.pageTitleField?.subtitle;
	const MainTitleImage = contactdata?.page?.pageTitleField?.titleImage?.mediaItemUrl;
	const map = contactdata?.page?.contactPage?.map;

	let content = contactdata?.page?.content;

	useEffect(() => {
		setTimeout(() => {
		    setIsLoading(false);
		}, 2000);
	}, []);

	useEffect(() => {
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	}, []);

	return (
		<>
			{isLoading ? (
                <Loader/>
            ) : (
			    <>
					<div className="sec_hdr" style={{ backgroundImage: `url(${MainTitleImage})` }}>
						<Secheader
							sectitle={MainTitle}
							secdesc={MainSubTitle}
						/>
					</div>
					<section>
						<div className="container row py-5 m-auto">
							<div className="col-md-12 col-lg-6 d-flex align-items-center justify-content-center order-1">
								{/* <form className="col-lg-8 col-12 col-md-10">
									<p className="display-6 mb-2">Reach us</p>
									<p>Our team is happy to answer your questions.</p>
									<div className="mb-3">
										<input type="text" className="form-control" id="name" placeholder="Your fullname" required />
									</div>
									<div className="mb-3">
										<input type="email" className="form-control" id="email" placeholder="E-mail address" required />
									</div>
									<div className="mb-3">
										<input type="tel" className="form-control" id="number" placeholder="Contact number" required />
									</div>
									<div className="mb-3">
										<textarea className="form-control form_textarea" id="message" placeholder="Your message" rows="3"></textarea>
									</div>
									<button className="btn btn-lg btn_custom btn_shadow col-12">Send</button>
								</form> */}
								{/* <div dangerouslySetInnerHTML={{ __html: content }} /> */}

								<form onSubmit={handleSubmit} className="col-lg-8 col-12 col-md-10 contact-form">
									<p className="display-6 mb-2">Reach us</p>
									<div className="mb-3">
										<input
											type="text"
											name="sname"
											className="form-control" 
											placeholder="Your name"
											value={formData.name}
											onChange={handleChange}
										/>
									</div>
									<div className="mb-3">
										<input
											type="email"
											name="email"
											className="form-control"
											placeholder="Your email"
											value={formData.email}
											onChange={handleChange}
										/>
									</div>
									<div className="mb-3">
										<textarea
											name="message"
											placeholder="Your message"
											className="form-control"
											value={formData.message}
											onChange={handleChange}
										/>
									</div>

									<button  className="btn btn-lg btn_custom btn_shadow col-12" type="submit">Submit</button>
								</form>
							</div>

							<div className="col-md-12 col-lg-6 d-flex align-items-center order-lg-1 order-md-2 flex-column py-5 text-left">
								<div className="row col-12 contact-details">
									<div className="col-md-6">
										<span className="lead mb-3">Support</span>
										<p className="small">
											Get help with all lorem ipsum products.<br /><br />
											<span className="m-2 ml-0 hdrcolor"><CallOutlinedIcon /></span><a href={supporttelLink} >{supportPhone}</a><br /><br />
											<span className="m-2 ml-0 hdrcolor"><EmailOutlinedIcon /></span><a href={supportmailtoLink} >{supportEmail}</a><br /><br />
											<span className="m-2 ml-0 hdrcolor"><LocationOnOutlinedIcon /></span>{supportAddress}
										</p>

									</div>
									<div className="col-md-6">
										<span className="lead mb-3">Sales</span>
										<p className="small">Purchase lorem ipsum products.<br /><br />
											<span className="m-2 ml-0 hdrcolor"><CallOutlinedIcon /></span><a href={salestelLink} >{salesPhone}</a><br /><br />
											<span className="m-2 ml-0 hdrcolor"><EmailOutlinedIcon /></span><a href={salesmailtoLink} >{salesEmail}</a><br /><br />
											<span className="m-2 ml-0 hdrcolor"><LocationOnOutlinedIcon /></span>{salesAddress}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="container-fluid p-0">
							<div className="pt-5 justify-content-center">
								<div className="col-12">
									{/* <iframe style={{width:'100%'}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3300.4822042773885!2d-118.60470402357237!3d34.1851539731072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c29e97f5255553%3A0xe48416c244da5e8a!2sQeWebby!5e0!3m2!1sen!2sin!4v1695635075693!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
									<div dangerouslySetInnerHTML={{ __html: map }} />
								</div>
							</div>
						</div>
					</section>
				</>
			)}
		</>
	);
};

export default Contact;