// import {ApolloClient, InMemoryCache } from "@apollo/client";

 


// export const client = new ApolloClient({
//     uri: URL,
//     cache: new InMemoryCache()
// });


import React from 'react';
//import fetch from 'node-fetch';
import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink, ApolloProvider } from '@apollo/client';

const URL = process.env.REACT_APP_URL 

/**
 * Middleware operation
 * If we have a session token in localStorage, add it to the GraphQL request as a Session header.
 */
const middleware = new ApolloLink((operation, forward) => {
  const session = localStorage.getItem("woo-session");

  if (session) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        "woocommerce-session": `Session ${session}`,
      },
    }));
  }

  return forward(operation);
});

const afterware = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (typeof window === 'undefined') {
      return response;
    }

    const context = operation.getContext();
    const { response: { headers } } = context;
    const session = headers.get("woocommerce-session");

    if (session) {

      if ("false" === session) {
        localStorage.removeItem("woo-session");
      } else if (localStorage.getItem("woo-session") !== session) {
        localStorage.setItem("woo-session", headers.get("woocommerce-session"));
      }
    }

    return response;
  });
});


export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: middleware.concat(afterware).concat(createHttpLink({ // Note the closing parenthesis here
    uri: URL, // Replace with your GraphQL endpoint
    fetch,
  })),
});