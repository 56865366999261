import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Secheader from '../component/Secondaryheader'

import { GetBlogs } from '../../services/graphql/query'
import { BlogPage } from '../../services/graphql/query'

import Loader from '../../components/Loader/Loader'
import './blog.css'


const BlogList = () => {
    const { loading, error, data } = useQuery(GetBlogs);
    const { loading: titleloading, error: titleerror, data: titledata } = useQuery(BlogPage);
    const [isLoading, setIsLoading] = useState(true);
    let BlogTitle = titledata?.page?.pageTitleField?.title;
    let BlogSubtitle = titledata?.page?.pageTitleField?.subtitle
    let BlogTitleImage = titledata?.page?.pageTitleField?.titleImage?.mediaItemUrl;
    let blogsArr = data?.posts?.nodes;
    useEffect(() => {
		setTimeout(() => {
		    setIsLoading(false);
		}, 2000);
	}, []);

    useEffect(() => {
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	}, []);
    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div>
                        <div className="sec_hdr" style={{ backgroundImage: `url(${BlogTitleImage})` }}>
                            <Secheader sectitle={BlogTitle} secdesc={BlogSubtitle} />
                        </div>
                        <section>
                            <div className="container py-5">
                                <div className="container pt-3">
                                    <div className="row">
                                        { blogsArr.map((bDetails)=>{
                                            let media = bDetails.featuredImage.node.mediaItemUrl;
                                            var sectionStyle = {
                                                backgroundImage: "url("+media+")"
                                            };
                                            return (
                                                <article className="post__card-2">
                                                    <div className="post__card_-2">
                                                        <div className="post__card__image-2" style={sectionStyle}></div>
                                                        <div>
                                                            <div className="post__card_meta-2">
                                                                <div className="post__card_cat">category</div>
                                                                <Link to={`/blog/${bDetails.slug}`}><h5>{bDetails.title}</h5></Link>
                                                                <p className="text-muted text-left" dangerouslySetInnerHTML={{__html: bDetails.excerpt}}></p>
                                                                <a className='post__card_link' href={`/blog/${bDetails.slug}`}>Read More</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </>
            )}
        </>
    )
}

export default BlogList