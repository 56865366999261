import React from "react";
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import MapIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import './footer.css'
import { ALL_OPTIONS } from "../../services/graphql/query";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

const Footer = () => {
	const { loading, error, data:options } = useQuery(ALL_OPTIONS);
	console.log( options );
	let footerText = options?.page?.options?.footerText;
	let facebookUrl = options?.page?.options?.facebook;
	let twitterUrl = options?.page?.options?.twitter;
	let instagramUrl = options?.page?.options?.instagram;
	let linkedinUrl = options?.page?.options?.linkedin;
	let aboutUs = options?.page?.options?.aboutUs;
	let address = options?.page?.options?.address;
	let phone = `tel:${options?.page?.options?.phone}`;
	let email = `mailto:${options?.page?.options?.email}`;

	return (
		<>
			<footer>
			<div className="container">
					<div className="footer-top">
						<div className="row">
							<div className="col-md-6 col-lg-3 page-more-info">
								<div className="footer-title">
									<h4>About Us</h4>
								</div>
								<p>{aboutUs}</p>
							</div>
							<div className="col-md-6 col-lg-3 about-footer">
							<div className="footer-title">
									<h4>Page links</h4>
								</div>
								<ul>
									<li><Link to="/">Home</Link></li>
									<li><Link to="/about">About</Link></li>
									<li><Link to="/service">Service</Link></li>
									<li><Link to="/blog">Blogs</Link></li>
									<li><Link to="/contact">Contact</Link></li>
								</ul>
							</div>
							<div className="col-md-6 col-lg-3 page-more-info">
								<div className="footer-title">
									<h4>Our Services</h4>
								</div>
								<ul>
									<li><Link to="/service/ideas-into-business">Ideas into business</Link></li>
									<li><Link to="/service/digital-marketing">Digital Marketing</Link></li>
									<li><Link to="/service/graphic-designing">Graphic Designing</Link></li>
									<li><Link to="/service/product-building">Product Building</Link></li>
									<li><Link to="/service/data-security">Data Security</Link></li>
									<li><Link to="/service/building-e-commerce">Building e-commerce</Link></li>
								</ul>
							</div>

							
							<div className="col-md-6 col-lg-3 open-hours">
								<div className="footer-title">
									<h4>Contact </h4>
								</div>
								<ul>
									<li><MapIcon fontSize="small" />
										<span dangerouslySetInnerHTML={{__html: address}}></span>
									</li>
									<li><a href={phone}><PhoneIcon fontSize="small" /> {options?.page?.options?.phone}</a></li>
									<li><a href={email}><EmailIcon fontSize="small" /> {options?.page?.options?.email}</a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="container d-lg-flex align-items-center justify-content-between py-3 small text-center">
					<div className="text-white small">{footerText}</div>
					<div className="text-white mt-3 mt-lg-0">
						<a href={twitterUrl} target="blank"><span className="mx-2"><TwitterIcon fontSize="small" /></span></a>
						<a href={facebookUrl} target="blank"><span className="mx-2"><FacebookIcon fontSize="small" /></span></a>
						<a href={instagramUrl} target="blank"><span className="mx-2"><InstagramIcon fontSize="small" /></span></a>
						<a href={linkedinUrl} target="blank"><span className="mx-2"><LinkedInIcon fontSize="small" /></span></a>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;