import React from "react";
import {NavLink} from "react-router-dom";
//import logo from "../../images/main.svg"
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import DvrIcon from '@mui/icons-material/Dvr';
import { useQuery } from "@apollo/client";
import { ALL_OPTIONS } from "../../services/graphql/query";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import BookIcon from '@mui/icons-material/Book';

const Navbar = () => {

	const { loading, error, data:options } = useQuery(ALL_OPTIONS);
	let logo = options?.page?.options?.logo?.mediaItemUrl;
	let logoAlt = ( options?.page?.options?.logo?.altText ) ? options?.page?.options?.logo?.altText : 'Logo';
	
	return(
		<>
			<div className="container-fluid navigation_bar">
				<nav className="navscroll navbar fixed-top navbar-expand-lg navbar-light py-2 px-3">
					<div className="container">
						<NavLink className="navbar-brand" to="/"><img src={logo} alt={logoAlt} className="img-fluid" width="160" /></NavLink>
						<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
							<span className="text-white"><MenuIcon style={{ fontSize: 35 }} /></span>
						</button>
						<div className="collapse navbar-collapse" id="navbarSupportedContent">
							<ul className="navbar-nav ml-auto">
								<li className="nav-item active">
									<NavLink exact className="nav-link" to="/"><span className="d-lg-none d-xl-none mr-3"><HomeIcon /></span>Home</NavLink>
								</li>
								<li className="nav-item">
									<NavLink exact className="nav-link" to="/about"><span className="d-lg-none d-xl-none mr-3"><PeopleIcon /></span>About</NavLink>
								</li>
								<li className="nav-item">
									<NavLink exact className="nav-link" to="/service"><span className="d-lg-none d-xl-none mr-3"><DvrIcon /></span>Services</NavLink>
								</li>
								<li className="nav-item">
									<NavLink exact className="nav-link" to="/blog"><span className="d-lg-none d-xl-none mr-3"><BookIcon /></span>Blogs</NavLink>
								</li>
								<li className="nav-item">
									<a exact className="nav-link" href="https://headless.qewebby.com/shop"><span className="d-lg-none d-xl-none mr-3"><ShoppingCartIcon /></span>Shop</a>
								</li>
								<li className="nav-item">
									<NavLink exact className="nav-link" to="/contact"><span className="d-lg-none d-xl-none mr-3"><ContactPageIcon /></span>Contact</NavLink>
								</li>
								{/* <li className="nav-item">
									<a href="#responsive-header" className="nav-link">
										<svg xmlns="http://www.w3.org/2000/svg" className="hidden lg:block m-auto" fill="none" viewBox="0 0 24 24" width="18" height="auto" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" /></svg>
											Profile
									</a>
								</li>
								<li>
									<a href="#responsive-header" className="nav-link">
										<svg xmlns="http://www.w3.org/2000/svg" className="hidden lg:block m-auto" fill="none" viewBox="0 0 24 24" width="18" height="auto" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" /></svg>
											Wishlist
									</a>
								</li> */}
							</ul>
						</div>
					</div>
				</nav>
			</div>
		</>
	);
};

export default Navbar;