import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Secheader from '../component/Secondaryheader'
import { BlogSinglePage } from "../../services/graphql/query";
import { useQuery } from "@apollo/client";
import Loader from '../../components/Loader/Loader';

const BlogDetails = () => {
    const { slug } = useParams();
    const { loading, error, data } = useQuery(BlogSinglePage, {
        variables: { slug },
    });
    let Title = data?.postBy?.title;
    let Content = data?.postBy?.content;
    let TitleImage = data?.postBy?.pageTitleField?.titleImage?.mediaItemUrl

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
		setTimeout(() => {
		    setIsLoading(false);
		}, 2000);
	}, []);

    useEffect(() => {
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	}, []);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className="sec_hdr" style={{ backgroundImage: `url(${TitleImage})` }}>
                        <Secheader sectitle={Title} />
                    </div>
                    <section>
                        <div className="container mx-auto py-5 col-lg-10 col-xl-9">
                            <div className="container mx-auto col-lg-10 col-xl-9">
                                <div className="container pt-3">
                                    <div className="row">
                                        <div className="main-- container" dangerouslySetInnerHTML={{__html: Content}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}
        </>
    )
}

export default BlogDetails