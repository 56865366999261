import { Suspense, lazy, useEffect, useState } from "react";

import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";

import Service from "./pages/Service";
import ServiceDetails from "./pages/serviceDetails/ServiceDetails";
import Contact from "./pages/Contact";
import About from "./pages/About";
// import Shop from "./pages/woocommerce/ShopPage";
// import Cart from "./pages/woocommerce/Cart";
// import Checkout from "./pages/woocommerce/Checkout";
//import ShopDetails from "./pages/ShopDetails";
//import Career from "./pages/Career";
import { Route, Routes,  } from "react-router-dom";
import BlogList from "./pages/blog/BlogList";
import BlogDetails from "./pages/blogDetails/BlogDetails";
import Loader from "./components/Loader/Loader";
import Layout from "./components/Layout/Layout";


const Home = lazy(() => import("./pages/Home"));

function App() {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => {
        setIsLoading(false);
        }, 2000);
    }, []);

    return (
        <div className="App">
            <Layout>
                {isLoading ? (
                <Loader />
                ) : (
                    <Suspense fallback={<div>Loading</div>}>
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="/about" element={<About />} />
                        <Route exact path="/service" element={<Service />} />
                        <Route exact path="/service/:slug" element={<ServiceDetails />} />
                        {/* <Route  path="/career" element={<Career/>} /> */}
                        <Route exact path="/contact" element={<Contact />} />
                        {/* <Route exact path="/shop" element={<Shop />} />
                        <Route exact path="/cart" element={<Cart />} /> */}
                        <Route exact path="/blog" element={<BlogList />} />
                        <Route exact path="/blog/:slug" element={<BlogDetails />} />
                        {/* <Route exact path="/checkout" element={<Checkout />} /> */}
                        {/* <Route exact path="/product/:slug" element={<ShopDetails />} /> */}
                    </Routes>
                    </Suspense>
                )}
            </Layout>
        </div>
    );
}

export default App;
