
import {gql} from "@apollo/client";

//cG9zdDo1Mzc=
export const ALL_OPTIONS = gql `query page {
    page(id: "cG9zdDo1Mzc=") {
    	options {
            aboutUs
            address
            email
            facebook
            twitter
            instagram
            linkedin
            fieldGroupName
            footerText
            logo {
                altText
                mediaItemUrl
            }
            phone
            salesAddress
            salesEmail
            salesPhone
            supportAddress
            supportEmail
            supportPhone
        }
    }
}`;

// export const ALL_OPTIONS = gql `query Options {
//     themeSettings {
//         options {
//             aboutUs
//             address
//             email
//             facebook
//             twitter
//             instagram
//             linkedin
//             fieldGroupName
//             footerText
//             logo {
//                 altText
//                 mediaItemUrl
//             }
//             phone
//             salesAddress
//             salesEmail
//             salesPhone
//             supportAddress
//             supportEmail
//             supportPhone
//         }
//     }
// }`;

export const HomePage = gql `query page {
    page(id: "cG9zdDoxNzM=") {
        id
        title
        home {
            sliderTitle1
            sliderDescription1
            sliderImage1 {
                mediaItemUrl
            }
            sliderButton1 {
                title
                url
                target
            }
            sliderTitle2
            sliderDescription2
            sliderImage2 {
                mediaItemUrl
            }
            sliderButton2 {
                title
                url
                target
            }
            whatWeDoTitle
            whatWeDoDesc
            threeBlocks1Title
            threeBlocks1Description
            threeBlocks1Image {
                mediaItemUrl
            }
            threeBlocks2Title
            threeBlocks2Description
            threeBlocks2Image {
                mediaItemUrl
            }
            threeBlocks3Title
            threeBlocks3Description
            threeBlocks3Image {
                mediaItemUrl
            }
            ourBrandTitle
            ourBrandDescription
            ourBrandImage {
                mediaItemUrl
                altText
            }
            ourBrandBgImage {
                mediaItemUrl
                altText
            }
            clientLogoTitle
            clientLogoDescription
            clientLogo1 {
                mediaItemUrl
            }
            clientLogo2 {
                mediaItemUrl
            }
            clientLogo3 {
                mediaItemUrl
            }
            clientLogo4 {
                mediaItemUrl
            }
            clientLogo5 {
                mediaItemUrl
            }
            clientLogo6 {
                mediaItemUrl
            }
            clientLogo7 {
                mediaItemUrl
            }
            clientLogo8 {
                mediaItemUrl
            }
            clientLogo9 {
                mediaItemUrl
            }
            clientLogo10 {
                mediaItemUrl
            }
            clientLogo11 {
                mediaItemUrl
            }
            clientLogo12 {
                mediaItemUrl
            }
            getInTouchTitle
            getInTouchDescription
            getInTouchButton {
                url
                title
                target
            }
        }
    }
}`;

export const AboutPage = gql `query page {
    page(id: "cG9zdDozMw==") {
        id
        title
        content
        pageTitleField {
            title
            subtitle
            titleImage {
                altText
                mediaItemUrl
            }
        }
        aboutUs {
            ourStoryTitle
            ourStoryContent
            ourTeamTitle
            ourTeamContent
        }
    }
}`;

export const ServicesPage = gql `{
    page(id: "cG9zdDozNg==") {
        id
        title
        pageTitleField {
            title
            subtitle
            titleImage {
                mediaItemUrl
            }
        }
        services {
        serviceTitle
        serviceShortDescription
        serviceBottomTitle
        serviceBottomDescription
        }
    }
}`;

export const ContactPage = gql `{
    page(id: "cG9zdDozOA==") {
        id
        title
        pageTitleField {
            title
            subtitle
            titleImage {
            mediaItemUrl
            }
        }
        content
        contactPage {
            map
        }
    }
}`;

export const GetServices = gql `{
    allServices {
        nodes {
            id
            title
            slug
            excerpt
            content
            status
            singleService {
                icon {
                    altText
                    mediaItemUrl
                }
            }
        }
    }
}`;

export const GetTeams = gql `{
  allTeams {
    nodes {
      id
      title
      content
      status
      featuredImage {
        node {
          altText
          mediaItemUrl
        }
      }
    }
  }
}`;

export const GetBlogs = gql `{
    posts {
        nodes {
            id
            slug
            title
            excerpt
            featuredImage {
                node {
                mediaItemUrl
                altText
                }
            }
            content
        }
    }
}`;

export const BlogPage = gql `{
    page(id: "cG9zdDoyNDE=") {
        id
        title
        pageTitleField {
            title
            subtitle
            titleImage {
                altText
                mediaItemUrl
            }
        }
    }
}`;

export const BlogSinglePage = gql`
    query GetPostBySlug($slug: String!) {
        postBy(slug: $slug) {
            id
            title
            content
            pageTitleField {
                title
                subtitle
                titleImage {
                    altText
                    mediaItemUrl
                }
            }
        }
    }
`;

export const ProductListing = gql `{
    products {
        edges {
            node {
                id
                name
                description
                slug
                status
                featuredImage {
                    node {
                        altText
                        mediaItemUrl
                    }
                }
            }
        }
    }
}`;

export const ServiceSingle = gql `query GetServiceBySlug($slug: String!) {
    servicesBy(slug: $slug) {
        title
        slug
        content
        pageTitleField {
            title
            subtitle
            titleImage {
                altText
                mediaItemUrl
            }
        }
    }
}
`;

export const GET_PRODUCTS = gql`
    {
        products(where: {status: "publish"}) {
        edges {
            node {
            id
            name
            description
            content
            ... on SimpleProduct {
                id
                name
                slug
                price
                productId
            }
            featuredImage {
                node {
                altText
                mediaItemUrl
                }
            }
            }
        }
        }
    }
`;

export const ShopPageData = gql `query page {
    page(id: "cG9zdDoyNjg=") {
        pageTitleField {
            title
            subtitle
            titleImage {
                altText
                mediaItemUrl
            }
        }
    }
}`;

export const ShopSingle = gql `query GetProductBySlug($slug: String!) {
    products(where: { slugIn: $slug }) {
      edges {
        node {
          id
          title
          # Add other fields you want to retrieve
        }
      }
    }
  }
`;

// export const ADD_TO_CART = gql`
//     mutation addToCart($productId: ID!) {
//         addToCart(productId: $productId) {
//             id
//             quantity
//         }
//     }
// `;



// const PRODUCTS = gql`
//     query GetProducts($first: Int, $last: Int, $after: String, $before: String) {
//   products(first: $first, last: $last, after: $after, before: $before) {
//     pageInfo {
//       endCursor
//       hasNextPage
//       hasPreviousPage
//       startCursor
//     }
//     edges {
//       cursor
//       node {
//           id
//           slug
//           name
//           type
//           databaseId
//           shortDescription
//           image {
//               id
//               sourceUrl
//               altText
//           }
//           galleryImages {
//               nodes {
//                   id
//                   sourceUrl
//                   altText
//               }
//           }
//           ... on SimpleProduct {
//               onSale
//               price
//               content
//               regularPrice
//           }
//           ... on VariableProduct {
//               onSale
//               price
//               content
//               regularPrice
//           }
//       }
//   }
//   }
// }
// `;